.footer {
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(135deg, #a6badf, #95cdd1); /* Gradient background */
    padding: 10px 20px;
    position: relative;
    height: 80px; /* Adjust based on your preference */
}

/* src/components/Footer.css */
.footer {
    background-color: #333;
    color: white;
    padding: 20px;
    text-align: center;
}

.footer-logo-container {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

.footer-logo {
    height: 50px;
    width: auto;
}

.footer-content {
    font-size: 14px;
}

/* Media Queries for Mobile */
@media (max-width: 600px) {
    .footer {
        padding: 10px;
    }

    .footer-logo {
        height: 40px;
    }

    .footer-content {
        font-size: 12px;
    }
}