.header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background: linear-gradient(135deg, #a6badf, #95cdd1); /* Gradient background */
    height: 70px;
    position: relative;
}

.logo-container {
    position: absolute;
    left: 20px;
}

.logo {
    width: 100px;
    height: auto;
    border-radius: 50%;
}

.header-content {
    text-align: center;
    color: #333;
}

.header-title {
    font-size: 2.2rem;
    margin: 0;
    color: #2c3e50;
    font-family: 'Arial', sans-serif;
}

.header-subtitle {
    font-size: 1.2rem;
    margin: 0;
    color: #555;
    font-family: 'Arial', sans-serif;
}

.header-org-number {
    font-size: 1rem;
    margin: 0;
    color: #777;
    font-family: 'Arial', sans-serif;
}

/* Media Queries for Mobile */
@media (max-width: 600px) {
    .header {
        flex-direction: column;
        height: auto;
        padding: 10px;
    }

    .logo {
        display: none;
    }

    .header-title {
        font-size: 1.8rem;
    }

    .header-subtitle {
        font-size: 1rem;
    }

    .header-org-number {
        font-size: 0.8rem;
    }
}