/* src/components/ImageSlider.css */
.slider-container {
    width: 100%;
    max-width: 800px; /* Limit maximum width */
    margin: auto; /* Center the slider */
}

.slider-container img {
    width: 100%; /* Responsive image */
    height: auto; /* Maintain aspect ratio */
}
