
nav {
    background: linear-gradient(135deg, #8bb5e8, #95cdd1); /* Gradient background */
    color: black;
    padding: 10px 0;
}

nav ul {
    list-style: none;
    display: flex;
    justify-content: center;
    padding: 0;
    flex-wrap: wrap; /* Allow wrapping on small screens */
}

nav ul li {
    margin: 0 15px;
}

nav a {
    color: black;
    text-decoration: none;
}

/* Hamburger menu styles */
.hamburger {
    display: none;
    flex-direction: column;
    cursor: pointer;
}

.hamburger div {
    width: 50px;
    height: 2px;
    background-color: black;
    margin: 5px;
    transition: 0.4s;
}

nav ul {
    display: flex;
    justify-content: center;
    padding: 0;
    flex-wrap: wrap; /* Allow wrapping on small screens */
}

@media (max-width: 600px) {
    .hamburger {
        display: flex;
    }

    nav ul {
        display: none;
        flex-direction: column;
        width: 100%;
    }

    nav ul.active {
        display: flex;
    }

    nav ul li {
        margin: 10px 0;
    }
}