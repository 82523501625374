.about-us {
    padding: 40px;
    max-width: 1200px;
    margin: 0 auto;
}

h1, h2 {
    font-size: 2.5rem;
    text-align: center;
    color: #333;
    margin-bottom: 20px;
}

.mission-section, .objectives-section, .board-section {
    margin-bottom: 40px;
}

p, ul {
    font-size: 1.1rem;
    line-height: 1.6;
    color: #555;
}

ul {
    list-style-type: disc;
    padding-left: 20px;
}

.board-section {
    text-align: center;
}

.board-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.board-card {
    width: 200px;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    padding: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s ease;
}

.board-card:hover {
    transform: scale(1.05);
}

.board-image {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 50%;
    margin-bottom: 10px;
}

h3 {
    font-size: 1.2rem;
    margin: 10px 0 5px;
    color: #444;
}

p {
    font-size: 1rem;
    color: #666;
}
