.events-container {
    padding: 40px;
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
}

h1 {
    font-size: 2.5rem;
    margin-bottom: 40px;
    color: #333;
}

.festivals-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.festival-card {
    width: 200px;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
}

.festival-card:hover {
    transform: scale(1.05);
}

.festival-image {
    width: 100%;
    height: 150px;
    object-fit: cover;
}

h2 {
    font-size: 1.2rem;
    color: #444;
    padding: 10px 0;
}
