body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background: linear-gradient(135deg, #a6badf, #95cdd1); /* Gradient background */
}

main {
    padding: 20px;
}

form {
    display: flex;
    flex-direction: column;
}

form label {
    margin-bottom: 5px;
}

form input,
form textarea {
    margin-bottom: 15px;
    padding: 10px;
    width: 100%; /* Ensure full width on mobile */
    box-sizing: border-box; /* Include padding in width */
}

button {
    background: #333;
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    width: 100%; /* Full width on mobile */
}

button:hover {
    background: #555;
}

/* Media Queries for Mobile */
@media (max-width: 600px) {
    nav ul {
        flex-direction: column;
        align-items: center;
    }

    nav ul li {
        margin: 10px 0;
    }

    main {
        padding: 10px; /* Reduce padding on small screens */
    }

    form input,
    form textarea,
    button {
        font-size: 16px; /* Increase font size for better touch targets */
    }
}

.icon {
    font-size: 72px;
    color: #000;
    margin: 0 10px;
    transition: transform 0.3s;
}

.icon.facebook:hover {
    transform: scale(1.2);
    color: #3b5998;
}

.icon.twitter:hover {
    transform: scale(1.2);
    color: #1da1f2;
}

.icon.instagram:hover {
    transform: scale(1.2);
    color: rgba(182, 10, 166, 0.89);
}

.icon.youtube:hover {
    transform: scale(1.2);
    color: #e4405f;
}