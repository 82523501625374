.contact-us-container {
    padding: 40px;
    max-width: 1000px;
    margin: 0 auto;
    text-align: center;
}

h1 {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 20px;
}

p {
    font-size: 1.1rem;
    color: #555;
    margin-bottom: 40px;
}
